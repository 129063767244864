document.addEventListener('DOMContentLoaded', () => {
  document.body.classList.add('js-loading');

  const scrollbarWidth = window.innerWidth -
    document.documentElement.clientWidth;

  const CLASS_OPEN = 'menu-open';
  const CLASS_HIDDEN = 'hidden';
  const TEXT_MENU = 'Menu';
  const TEXT_CLOSE = 'Close';

  const nav = document.getElementsByClassName('main-nav')[0];
  const menu = document.getElementById('menu');
  const altNav = document.getElementsByClassName('alt-nav')[0];

  if (nav && menu) {
    const menuBtn = document.createElement('a');
    let isMenuOpen = false;

    // Setup menu
    menuBtn.href = '#menu';
    menuBtn.setAttribute('class', 'menu-btn');
    menuBtn.setAttribute('aria-live', 'ignore');
    menuBtn.innerText = TEXT_MENU;
    menu.parentNode.insertBefore(menuBtn, menu.nextSibling);

    menu.classList.add(CLASS_HIDDEN);

    const focusableElements = nav.querySelectorAll('button, [href]');
    const firstFocusable = focusableElements[0];
    const lastFocusable = focusableElements[focusableElements.length - 1];

    window.addEventListener('popstate', () => {
      // Close the menu when going back if the menu was just open, and
      // open it if going forward
      if (document.location.hash === '#menu') {
        openMenu();
      } else if (isMenuOpen) {
        closeMenu(false);
      }
    });

    menu.addEventListener('transitionend', (e) => {
      if (!isMenuOpen) {
        menu.classList.add(CLASS_HIDDEN);
      }
    });

    setTimeout(() => {
      document.body.classList.remove('js-loading');
    }, 60);

    function openMenu() {
      window.scrollTo({ top: 0 });
      menuBtn.innerText = TEXT_CLOSE;
      menu.classList.remove(CLASS_HIDDEN);
      document.body.classList.add(CLASS_OPEN);
      // Prevent layout shift when overflow: hidden removes the scrollbar
      document.body.style.marginRight = `${scrollbarWidth}px`;
      isMenuOpen = true;
    }

    function closeMenu(goBack) {
      if (isMenuOpen) {
        menuBtn.innerText = TEXT_MENU;
        document.body.classList.remove(CLASS_OPEN);
        document.body.style.marginRight = 0;
        isMenuOpen = false;

        if (goBack) {
          history.back();
        }
      }
    }

    menuBtn.addEventListener('click', (e) => {
      if (isMenuOpen) {
        e.stopPropagation();
        e.preventDefault();
        closeMenu(true);
      } else {
        openMenu();
      }
    });

    document.body.addEventListener('keydown', (e) => {
      if (e.key === 'Escape' || e.keyCode === 27) {
        closeMenu(true);
      }

      if (isMenuOpen) {
        if (e.key === 'Tab' || e.keyCode === 9) {
          if (e.shiftKey) {
            if (document.activeElement === firstFocusable) {
              lastFocusable.focus();
              e.preventDefault();
            }
          } else {
            if (document.activeElement === lastFocusable) {
              firstFocusable.focus();
              e.preventDefault();
            }
          }
        }
      }
    });
  } else if (altNav) {
    const menuButton = altNav.querySelector('button');
    menuButton.addEventListener('click', () => {
      menuButton.setAttribute(
        'aria-expanded',
        menuButton.getAttribute('aria-expanded') !== 'true'
      );
    });
  }

  // const form = document.getElementById('newsletter-form');
  // if (form && window.fetch) {
  //   let statusMsg = form.querySelector('.status');
  //   const submit = form.querySelector('button');
  //
  //   if (!statusMsg) {
  //       statusMsg = document.createElement('p');
  //       statusMsg.className = 'status';
  //       form.appendChild(statusMsg);
  //   }
  //
  //   statusMsg.setAttribute('aria-live', 'polite');
  //
  //   form.addEventListener('submit', (e) => {
  //       e.preventDefault();
  //       e.stopPropagation();
  //
  //       submit.disabled = true;
  //
  //       fetch(
  //           form.action,
  //           {
  //               method: 'POST',
  //               headers: {
  //                   'X-Requested-With': 'XMLHttpRequest',
  //                   'Accept': 'application/json'
  //               },
  //               body: new FormData(form)
  //           }
  //       )
  //       .then((response) => {
  //           response.json().then((data) => {
  //               if (data.ok) {
  //                   statusMsg.classList.remove('error-msg');
  //                   statusMsg.innerHTML = data.message + ' '
  //                   + '<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" class="icon icon--checkmark" aria-hidden="true" role="img" focusable="false">'
  //                   + '<path fill-rule="evenodd" fill="#6760ff" d="m28.778 13.05 1.414 1.414-12.728 12.728-1.414-1.414zm-15.556 7.071 4.242 4.243-1.414 1.414-4.242-4.242z"/>'
  //                   + '</svg>';
  //                   form.querySelector('.form__fields').style.display = 'none';
  //               } else {
  //                   console.log(data);
  //                   statusMsg.classList.add('error-msg');
  //                   statusMsg.innerHTML = data.error || data.message || 'Unknown error';
  //               }
  //           });
  //       })
  //       .catch((error) => {
  //           console.error(error);
  //           statusMsg.classList.add('error-msg');
  //           statusMsg.innerHTML = 'We’re sorry; there’s been an unexpected error.';
  //       })
  //       .finally(() => {
  //           submit.disabled = false;
  //       });
  //   });
  // }
  //
  // function debounce(func, wait, immediate) {
  //   let timeout;
  //   return function() {
  //     const context = this,
  //       args = arguments;
  //     const later = function() {
  //       timeout = null;
  //       if (!immediate) func.apply(context, args);
  //     };
  //     const callNow = immediate && !timeout;
  //     clearTimeout(timeout);
  //     timeout = setTimeout(later, wait);
  //     if (callNow) func.apply(context, args);
  //   };
  // };
  //
  // function detectScroll() {
  //   const threshold = 16 * (-1);
  //   const CLASS = 'scroll-past-first-entrepreneur';
  //   const rect = document.querySelectorAll('.investments__entrepreneur')[0].getBoundingClientRect();
  //
  //   if ( window.scrollY - threshold > rect.top + window.scrollY) {
  //     document.body.classList.add(CLASS);
  //   } else {
  //     document.body.classList.remove(CLASS);
  //   }
  // }
  //
  // if (document.querySelectorAll('.investments__entrepreneur').length) {
  //   window.addEventListener('scroll', debounce(detectScroll, 16, true));
  //   detectScroll();
  // }
  //
  // // See https://bugzilla.mozilla.org/show_bug.cgi?id=645075#c43
  // if (document.location.hash && navigator.product === 'Gecko') {
  //   setTimeout(function() { document.location.href += ''; }, 10);
  // }
  //
  // document.documentElement.classList.add('js');


  // Block setup
  if (document.querySelector('.block--people')) {
    const icon = document.querySelector('.block--people__icon svg');

    function toggleBio() {
      const expanded = this.getAttribute('aria-expanded') === 'true';
      const section = document.getElementById(
        this.getAttribute('aria-controls')
      );
      const label = this.querySelector('span');

      this.setAttribute('aria-expanded', !expanded);

      if (expanded) {
        label.innerText = 'More';
        section.setAttribute('hidden', true);
      } else {
        label.innerText = 'Less';
        section.removeAttribute('hidden');
      }
    }

    const sections = Array.prototype.slice.call(
      document.querySelectorAll(".block--people__bio__more")
    );

    sections.forEach((section, i) => {
      // Add button
      const button = document.createElement('button');
      const label = document.createElement('span');
      section.id = `people-expand-${i + 1}`;
      button.setAttribute('aria-controls', section.id);
      button.setAttribute('aria-expanded', true);
      button.setAttribute('aria-live', 'polite');
      label.innerText = 'More';
      button.appendChild(label);
      button.appendChild(icon.cloneNode(true));
      section.parentNode.insertBefore(button, section);

      toggleBio.call(button);
      button.addEventListener('click', toggleBio);
    });
  }

  if (document.querySelector('.block--faq')) {
    const groups = Array.prototype.slice.call(
      document.querySelectorAll('.block--faq__group')
    );
    const icon = document.querySelector('.block--faq__icon svg');

    if (!groups || !icon) {
      return;
    }

    // Open/Close FAQ groups
    function toggleFAQ() {
      const group = document.getElementById(this.getAttribute('aria-controls'));

      if (group) {
        const expanded = this.getAttribute('aria-expanded') === 'true';

        this.setAttribute('aria-expanded', !expanded);

        if (expanded) {
          group.setAttribute('hidden', true);
        } else {
          group.removeAttribute('hidden');
        }
      }
    }

    // Navigate section titles via up/down and home/end keys
    function handleKey(e) {
      const first = 0;
      const last = groups.length - 1;
      const index = Number.parseInt(e.target.getAttribute('data-index'), 10);

      let next;
      switch (e.key) {
        case 'ArrowDown':
          if (index < last) {
            next = index + 1;
          } else {
            next = first;
          }
          break;
        case 'ArrowUp':
          if (index === first) {
            next = last;
          } else {
            next = index - 1;
          }
          break;
        case 'Home':
          next = first;
          break;
        case 'End':
          next = last;
          break;
      }

      if (next !== undefined && next !== index) {
        e.preventDefault();
        groups[next].previousElementSibling.querySelector('button').focus();
      }
    }

    // Add open/close functionality
    groups.forEach((group, i) => {
      const title = document.getElementById(
        group.getAttribute('aria-labelledby')
      );
      if (!title) return;

      group.setAttribute('role', 'region');
      group.id = `faqs-${(i + 1)}`;

      // Add toggle button
      const button = document.createElement('button');
      button.id = title.id;
      title.id = '';
      button.setAttribute('data-index', i);
      button.setAttribute('aria-expanded', true);
      button.setAttribute('aria-controls', group.id);
      while (title.firstChild) {
        button.appendChild(title.firstChild);
      }

      button.appendChild(icon.cloneNode(true));
      title.appendChild(button);

      // Toggle all sections closed and add event listeners
      toggleFAQ.call(button);
      button.addEventListener('click', toggleFAQ);
      button.addEventListener('keydown', handleKey);
    });
  }
});

